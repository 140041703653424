import { GET_ALL_PATIENT_LIST, GET_PATIENT_APPOINTMENT_LIST, GET_PATIENT_PRESCRIPTION_LIST, UPDATE_USER_STATUS } from "constants/ApiConstant";
import { getRequest, patchRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction } = require("mobx");

class PatientStore {
  allPatientList = [];
  patientAppointmentList = [];
  patientPrescriptionList = [];

  isLoadingPrescriptionList= false;
  isLoadingPatientList = false;
  isLoadingPatientAppointmentList = false;

  constructor() {
    makeAutoObservable(this);
  }

  getAllPatientList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_ALL_PATIENT_LIST });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setAllPatientList(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  getPatientAppointmentList = async (patientId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_PATIENT_APPOINTMENT_LIST}/${patientId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setPatientAppointmentList(data);
          return resolve({ success: true, data: data });
        }

        this.setPatientAppointmentList();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPatientAppointmentList();
        return reject({ success: false, data: [] });
      }
    });
  };


  getPatientPrescriptionList = async (patientId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_PATIENT_PRESCRIPTION_LIST}/${patientId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setPatientPriscriptionList(data);
          return resolve({ success: true, data: data });
        }

        this.setPatientPriscriptionList();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPatientPriscriptionList();
        return reject({ success: false, data: [] });
      }
    });
  };

  updateUserStatus = async (userId, status) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({ url: `${UPDATE_USER_STATUS}/${userId}/${status}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        this.getAllPatientList();
        return resolve({ success: false, data: [] });

      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setAllPatientList = (data = []) => {
    runInAction(() => {
      this.allPatientList = data;
    });
  };
  setPatientAppointmentList = (data = []) => {
    runInAction(() => {
      this.patientAppointmentList = data;
    });
  };
  setPatientPriscriptionList = (data = []) => {
    runInAction(() => {
      this.patientPrescriptionList = data;
    });
  };

  setIsLoadingPatientList = (value = false) => {
    runInAction(() => {
      this.isLoadingPatientList = value;
    });
  };
  setIsLoadingPatientAppointmentList = (value = false) => {
    runInAction(() => {
      this.isLoadingPatientAppointmentList = value;
    });
  };
  setIsLoadingPrescriptionList = (value = false) => {
    runInAction(() => {
      this.isLoadingPrescriptionList = value;
    });
  };
}

export default PatientStore;
