import { ADD_NEW_ADMIN_USER, GET_ALL_ADMIN_USERS, GET_PAYMENT_PARTNERS, GET_PLATFORM_CHARGE, GET_TNC, UPDATE_PAYMENT_PARTNERS, UPDATE_PLATFORM_FEE, UPDATE_TNC } from "constants/ApiConstant";
import { deleteRequest, getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction, toJS } = require("mobx");

class AdminSettingStore {
  paymentPartners = [];
  PlatformCharge = [];
  TnC = { doctor: "", patient: "", privacyPolicy: "" };
  isUpdatingTnC = false;
  isLoadingTnC = true;
  isLoadingAdminData = true

  allAdminData = []

  isLoadingForGetPaymentOption = true;
  isLoadingForUpdatePaymentOption = false;

  isLoadingForGetPlatformCharge = true;
  isLoadingForUpdatePlatformCharge = false;

  constructor() {
    makeAutoObservable(this);
  }

  getPaymentPartners = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_PAYMENT_PARTNERS });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          const [{ _id, paypal, stripe } = {}] = data;
          // this.setInitialPaymentOption({ ...this.initialPaymentOption, paypal: paypal, stripe: stripe, _id: _id });
          this.setPaymentPartners(data);
          return resolve({ success: true, data: data });
        }
        this.setPaymentPartners();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPaymentPartners();
        return reject({ success: false, data: [] });
      }
    });
  };
  updatePaymentPartners = async (paymentOption) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({
          url: UPDATE_PAYMENT_PARTNERS,
          payload: paymentOption,
        });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError) {
          this.setPaymentPartners(data);
          return resolve({ success: true, data: data });
        }
        // this.setPaymentPartners();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPaymentPartners();
        return reject({ success: false, data: [] });
      }
    });
  };

  // platform charge

  getPlatformCharge = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_PLATFORM_CHARGE });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          const [{ _id, paypal, stripe } = {}] = data;
          // this.setInitialPaymentOption({ ...this.initialPaymentOption, paypal: paypal, stripe: stripe, _id: _id });
          this.setPlatformCharge(data);
          return resolve({ success: true, data: data });
        }
        this.setPlatformCharge();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPlatformCharge();
        return reject({ success: false, data: [] });
      }
    });
  };

  updatePlatformCharge = async (id, paymentOption) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({
          url: `${UPDATE_PLATFORM_FEE}/${id}`,
          payload: paymentOption,
        });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setPlatformCharge(data);
          return resolve({ success: true, data: data });
        }
        // this.setPlatformCharge();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPlatformCharge();
        return reject({ success: false, data: [] });
      }
    });
  };

  getTnC = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_TNC });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: false });

        if (!error && !responseError && isValidArray(data)) {
          const [{ doctor, patient, privacyPolicy }] = data
          this.setTnC({ doctor, patient, privacyPolicy });
          return resolve({ success: true, data: data });
        }
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setTnC();
        return reject({ success: false, data: [] });
      }
    });
  };

  addOrUpdateTnC = async () => {
    return new Promise(async (resolve, reject) => {
      try {

        const { error, response } = await postRequest({ url: UPDATE_TNC, payload: { doctor: this.TnC.doctor, patient: this.TnC.patient, privacyPolicy: this.TnC.privacyPolicy } });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });


        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });

      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  }

  addNewAdminUser = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {

        const newBody = {
          ...body,
          type: "66f3a47b2be96299b4909d7a",
        }

        const { error, response } = await postRequest({ url: ADD_NEW_ADMIN_USER, payload: newBody });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });

      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  }

  getAllAdminUsers = async () => {
    return new Promise(async (resolve, reject) => {
      try {

        const { error, response } = await getRequest({ url: GET_ALL_ADMIN_USERS });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: false });
        
        if (!error && !responseError && isValidArray(data)) { 
          this.setAllAdminData(data);
          return resolve({ success: true, data: data });
        }

        this.setAllAdminData(data);
        return reject({ success: false, data: [] }); 
      } catch (error) {
        this.setAllAdminData();
        return reject({ success: false, data: [] });
      } 
    });
  }

  setPaymentPartners = (value = []) => {
    runInAction(() => {
      this.paymentPartners = value;
    });
  };

  setPlatformCharge = (value = []) => {
    runInAction(() => {
      this.PlatformCharge = value;
    });
  };
  // setInitialPaymentOption = (value = {}) => kj{
  //   runInAction(() => {
  //     this.initialPaymentOption = value;
  //   });
  // };

  setIsLoadingForGetPaymentOption = (value = false) => {
    runInAction(() => {
      this.isLoadingForGetPaymentOption = value;
    });
  };
  setIsLoadingForGetPlatformCharge = (value = false) => {
    runInAction(() => {
      this.isLoadingForGetPlatformCharge = value;
    });
  };
  setIsLoadingForUpdatePlatformCharge = (value = false) => {
    runInAction(() => {
      this.isLoadingForUpdatePlatformCharge = value;
    });
  };
  setIsLoadingForUpdatePaymentOption = (value = false) => {
    runInAction(() => {
      this.isLoadingForUpdatePaymentOption = value;
    });
  };

  setTnC = (value = { doctor: "", patient: "", privacyPolicy: "" }) => {
    runInAction(() => {
      this.TnC = { ...this.TnC, ...value };
    });
  };

  setIsLoadingTnC = (value = false) => {
    runInAction(() => {
      this.isLoadingTnC = value;
    });
  };

  setIsUpdatingTnC = (value = false) => {
    runInAction(() => {
      this.isUpdatingTnC = value;
    });
  };

  setAllAdminData = (data = []) => {
    runInAction(() => {
      this.allAdminData = data;
    });
  };

  setIsLoadingAdminData = (value = false) => {
    runInAction(() => {
      this.isLoadingAdminData = value;
    });
  };


}

export default AdminSettingStore;
