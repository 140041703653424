import { GET_NOTIFICATION, GET_USER_DETAILS_BY_TOKEN, READ_NOTIFICATION, USER_LOGIN } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class GlobalStore {

    loginUserAccessToken = ""

    isLoadingUserAccessToken = true
    isLogInLoader = false
    isLoadingUserDetails = true
    userData = [{}]
    adminNotifications = []

    constructor() {
        makeAutoObservable(this);
    }

    adminUserLogin = async (values) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error: apiError, response } = await postRequest({ url: USER_LOGIN, payload: values });
                const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

                if (!apiError && !responseError && isValidArray(data)) {
                    return resolve({ success: true, data: data })
                }

                return resolve({ success: false, data: [] })

            } catch (error) {
                reject({ success: false, data: [], message: error.message })
            }
        })
    }


    getUserDetailsByToken = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error, response } = await getRequest({ url: GET_USER_DETAILS_BY_TOKEN });
                const { data, error: responseError } = getValidDataFromResponse({ response });
                if (!error && !responseError && isValidArray(data)) {
                    this.setUserData(data)
                    this.setIsLoadingUserDetails(false)
                    return resolve({ success: true, data: data })
                }

                this.setIsLoadingUserDetails(false)
                return resolve({ success: false, data: [] })

            } catch (error) {
                this.setIsLoadingUserDetails(false)
                reject({ success: false, data: [], message: error.message })
            }
        })
    }



    setIsLogInLoader = (value = false) => {
        runInAction(() => {
            this.isLogInLoader = value
        })
    }

    setLoginUserAccessToken = (token = "") => {
        runInAction(() => {
            this.loginUserAccessToken = token
        })
    }

    setIsLoadingUserAccessToken = (value = false) => {
        runInAction(() => {
            this.isLoadingUserAccessToken = value
        })
    }

    setIsLoadingUserDetails = (value = false) => {
        runInAction(() => {
            this.isLoadingUserDetails = value
        })
    }

    setUserData = (data = [{}]) => {
        runInAction(() => {
            this.userData = data
        })
    }

    setAdminNotifications = (data = []) => {
        runInAction(() => {
            this.adminNotifications = data
        })
    }


    getNotifications = async (userId, userType) => {
        return new Promise(async (resolve, reject) => {
            try {
                
                const { response } = await getRequest({
                    url: `${GET_NOTIFICATION}/${userId}/${userType}`
                });
                
                const { data, error } = getValidDataFromResponse({ response });
                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data: data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            }
        });
    };
    readNotification = async (notificationId, userType) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({
                    url: `${READ_NOTIFICATION}/${userType}/${notificationId}`,
                });
                
                const { data, error } = getValidDataFromResponse({ response });
                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data: data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            } 
        });
    };
}

export default GlobalStore;
