import { makeObservable } from "mobx";

const { Manager } = require("socket.io-client")

class MainSocketStore {

    adminSocket = {}

    constructor({ globalStore }) {
        this.globalStore = globalStore

        const adminSocketManager = new Manager(process.env.REACT_APP_API_BASE_URL, {
            transports: ["websocket", "polling"],
            extraHeaders: ["websocket", "polling"],
            autoConnect: true,
            upgrade: true
        })

        makeObservable(this, {});

        this.adminSocket = adminSocketManager.socket('/admin')
        
        this.adminSocket.on('notifications', this.setAdminNotifications)
    }

    setAdminNotifications = (notificationsData) => {
        const { data, success } = notificationsData

        if(success) {
            this.globalStore.setAdminNotifications(data)
        } else {
            this.globalStore.setAdminNotifications([])
        }
    }

    getAdminNotifications = async () => {
        this.adminSocket.emit('notifications')
    }



}

export default MainSocketStore